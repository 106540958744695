import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  InputAdornment,
  useTheme,
  Typography,
  Grid,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const EditItemModal = ({ open, onClose, item, onSave }) => {
  const theme = useTheme();
  const [editedItem, setEditedItem] = React.useState(item || {});
  const [activeColors, setActiveColors] = React.useState([]);

  React.useEffect(() => {
    if (item) {
      setEditedItem(item);
      // Initialize active colors
      const colors = [];
      for (let i = 1; i <= 12; i++) {
        if (item[`cor_variante_${i}`]) {
          colors.push(i);
        }
      }
      setActiveColors(colors);
    }
  }, [item]);

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    
    // Format price with 2 decimal places
    if (field === 'preco_aprovado') {
      value = value.replace(/[^0-9.,]/g, '');
      if (value) {
        value = parseFloat(value.replace(',', '.')).toFixed(2);
      }
    }

    setEditedItem({
      ...editedItem,
      [field]: value
    });
  };

  const handleAddColor = () => {
    if (activeColors.length < 12) {
      const newIndex = activeColors.length === 0 ? 1 : Math.max(...activeColors) + 1;
      if (newIndex <= 12) {
        setActiveColors([...activeColors, newIndex]);
      }
    }
  };

  const handleRemoveColor = (indexToRemove) => {
    setActiveColors(activeColors.filter(index => index !== indexToRemove));
    setEditedItem(prev => {
      const newItem = { ...prev };
      newItem[`cor_variante_${indexToRemove}`] = '';
      return newItem;
    });
  };

  const handleSave = () => {
    // Ensure price is formatted correctly before saving
    const formattedItem = {
      ...editedItem,
      preco_aprovado: editedItem.preco_aprovado ? 
        parseFloat(editedItem.preco_aprovado.toString().replace(',', '.')).toFixed(2) : 
        null
    };
    onSave(formattedItem);
  };

  if (!item) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ 
        pb: 1,
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="h6" component="div">
          Editar Item {editedItem.codigo || '-'}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pt: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          {/* Main Fields */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Tipo de Peça"
                fullWidth
                value={editedItem.tipo_de_peca || ''}
                onChange={handleChange('tipo_de_peca')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Técnica"
                fullWidth
                value={editedItem.tecnica || ''}
                onChange={handleChange('tecnica')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descrição"
                fullWidth
                value={editedItem.descricao || ''}
                onChange={handleChange('descricao')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Tecido Principal"
                fullWidth
                value={editedItem.tecido_principal || ''}
                onChange={handleChange('tecido_principal')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Molde Base"
                fullWidth
                value={editedItem.molde_base || ''}
                onChange={handleChange('molde_base')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Venda"
                fullWidth
                value={editedItem.venda || ''}
                onChange={handleChange('venda')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Preço Aprovado"
                fullWidth
                value={editedItem.preco_aprovado || ''}
                onChange={handleChange('preco_aprovado')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Grade ERP"
                fullWidth
                value={editedItem.grade_erp || ''}
                onChange={handleChange('grade_erp')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Observações"
                fullWidth
                multiline
                rows={4}
                value={editedItem.observacoes || ''}
                onChange={handleChange('observacoes')}
                sx={{
                  '& .MuiInputBase-root': {
                    minHeight: '120px'
                  }
                }}
              />
            </Grid>

            {/* Color Variants Section */}
            <Grid item xs={12}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between',
                mb: 2 
              }}>
                <Typography variant="subtitle1" color="text.secondary">
                  Variantes de Cor
                </Typography>
                {activeColors.length < 12 && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleAddColor}
                    variant="outlined"
                    size="small"
                  >
                    Adicionar Cor
                  </Button>
                )}
              </Box>
              <Grid container spacing={2}>
                {activeColors.map((colorIndex) => (
                  <Grid item xs={12} sm={6} md={4} key={colorIndex}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <TextField
                        label={`Cor ${colorIndex}`}
                        fullWidth
                        value={editedItem[`cor_variante_${colorIndex}`] || ''}
                        onChange={handleChange(`cor_variante_${colorIndex}`)}
                      />
                      <IconButton 
                        onClick={() => handleRemoveColor(colorIndex)}
                        color="error"
                        size="small"
                        sx={{ mt: 1 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onClose} color="inherit">
          Cancelar
        </Button>
        <Button onClick={handleSave} variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditItemModal;
