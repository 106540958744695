import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  useTheme,
  alpha,
  IconButton,
  Popover,
  Stack,
  Button,
  Tooltip
} from '@mui/material';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Area,
} from 'recharts';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import TokenIcon from '@mui/icons-material/Token';
import MessageIcon from '@mui/icons-material/Message';
import GroupIcon from '@mui/icons-material/Group';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { getTokenUsage } from '../services/openaiService';

const Dashboard = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(dayjs(new Date().setDate(1))); // Primeiro dia do mês atual
  const [endDate, setEndDate] = useState(dayjs(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))); // Último dia do mês atual
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [data, setData] = useState({
    stats: {
      totalTokens: 0,
      totalMessages: 0,
      activeUsers: 0,
      avgTokensPerDay: 0,
    },
    dailyUsage: []
  });

  const fetchData = useCallback(async (start = startDate, end = endDate) => {
    try {
      setLoading(true);
      const tokenData = await getTokenUsage(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));

      const dailyUsage = (tokenData.daily_costs || []).map(day => ({
        date: new Date(day.timestamp * 1000).toLocaleDateString('pt-BR'),
        tokens: Math.floor(day.cost / 0.002)
      }));

      setData({
        stats: {
          totalTokens: tokenData.total_tokens || 0,
          totalMessages: 0,
          activeUsers: 0,
          avgTokensPerDay: dailyUsage.length ? Math.floor(tokenData.total_tokens / dailyUsage.length) : 0
        },
        dailyUsage: dailyUsage
      });
    } catch (error) {
      console.error('Erro ao carregar dados:', error);
      setData({
        stats: {
          totalTokens: 0,
          totalMessages: 0,
          activeUsers: 0,
          avgTokensPerDay: 0
        },
        dailyUsage: []
      });
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, getTokenUsage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDateClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  const handleDateClose = () => {
    setAnchorEl(null);
    setTempStartDate(null);
    setTempEndDate(null);
  };

  const handleStartDateChange = (newDate) => {
    const date = dayjs(newDate);
    if (date.isAfter(tempEndDate)) {
      setTempEndDate(date);
    }
    setTempStartDate(date);
  };

  const handleEndDateChange = (newDate) => {
    const date = dayjs(newDate);
    if (date.isBefore(tempStartDate)) {
      setTempStartDate(date);
    }
    setTempEndDate(date);
  };

  const handleConfirm = () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    fetchData(tempStartDate, tempEndDate);
    handleDateClose();
  };

  const open = Boolean(anchorEl);

  const formatNumber = (num) => {
    if (num >= 1000000) return `${(num / 1000000).toFixed(1)}M`;
    if (num >= 1000) return `${(num / 1000).toFixed(1)}K`;
    return num.toString();
  };

  const formatDateRange = (start, end) => {
    const formatDate = (date) => `${date.format('DD')}/${date.format('MMM').charAt(0).toUpperCase()}${date.format('MMM').slice(1, 3)}`;
    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  const StatCard = ({ title, value, subtitle, icon: Icon, color, tooltip }) => (
    <Paper
      elevation={3}
      sx={{
        p: { xs: 2, sm: 3 },
        height: '100%',
        minWidth: { xs: '100%', sm: '165px' },
        minHeight: '140px',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.paper',
        borderRadius: '16px',
        position: 'relative',
        overflow: 'hidden',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: theme.shadows[8],
        },
      }}
    >
      <Box sx={{ 
        position: 'relative', 
        zIndex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography 
          variant="h6" 
          color="text.secondary"
          sx={{ 
            fontSize: { xs: '1rem', sm: '1.25rem' },
            mb: 2,
            height: '40px', 
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            lineHeight: '20px'
          }}
        >
          {title}
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mb: 2,
          mt: 'auto' 
        }}>
          <Tooltip title={tooltip}>
            <Typography 
              variant="h4" 
              component="div" 
              sx={{ 
                color: color,
                fontSize: { xs: '1.5rem', sm: '2rem' },
                fontWeight: 'bold'
              }}
            >
              {value}
            </Typography>
          </Tooltip>
          <Icon sx={{ 
            fontSize: { xs: 32, sm: 40 },
            color: alpha(color, 0.7)
          }} />
        </Box>

        {subtitle && (
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              fontSize: { xs: '0.75rem', sm: '0.875rem' },
              opacity: 0.8
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </Paper>
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth={false}>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TrendingUpIcon sx={{ fontSize: 35 }} />
            Dashboard
          </Typography>
          <IconButton 
            onClick={handleDateClick}
            sx={{ 
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              }
            }}
          >
            <CalendarMonthIcon />
          </IconButton>
        </Box>
        <Typography variant="body1" color="text.secondary">
          Visão geral das métricas de {startDate.format('DD [de] MMMM [de] YYYY')} até {endDate.format('DD [de] MMMM [de] YYYY')}
        </Typography>
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleDateClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 8,
          sx: {
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)',
            overflow: 'hidden',
            width: 360
          }
        }}
      >
        <Box 
          sx={{ 
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box 
            sx={{ 
              p: 2.5,
              pb: 2,
              bgcolor: theme.palette.primary.main,
              color: 'white',
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
            }}
          >
            <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
              Selecionar Período
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.9 }}>
              Escolha o intervalo de datas para análise
            </Typography>
          </Box>

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <Box sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        mb: 1,
                        color: theme.palette.text.secondary,
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}
                    >
                      <Box 
                        sx={{ 
                          width: 6, 
                          height: 6, 
                          borderRadius: '50%', 
                          bgcolor: theme.palette.primary.main 
                        }} 
                      />
                      Data Inicial
                    </Typography>
                    <DatePicker
                      value={tempStartDate}
                      onChange={handleStartDateChange}
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          sx: {
                            '& .MuiOutlinedInput-root': {
                              borderRadius: 2,
                              backgroundColor: alpha(theme.palette.primary.main, 0.04),
                              transition: 'all 0.2s',
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.08),
                              },
                              '&.Mui-focused': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.12),
                              }
                            },
                          }
                        }
                      }}
                    />
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        mb: 1,
                        color: theme.palette.text.secondary,
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}
                    >
                      <Box 
                        sx={{ 
                          width: 6, 
                          height: 6, 
                          borderRadius: '50%', 
                          bgcolor: theme.palette.primary.main 
                        }} 
                      />
                      Data Final
                    </Typography>
                    <DatePicker
                      value={tempEndDate}
                      onChange={handleEndDateChange}
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                          sx: {
                            '& .MuiOutlinedInput-root': {
                              borderRadius: 2,
                              backgroundColor: alpha(theme.palette.primary.main, 0.04),
                              transition: 'all 0.2s',
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.08),
                              },
                              '&.Mui-focused': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.12),
                              }
                            },
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'flex-end',
                  gap: 1.5,
                  pt: 2,
                  mt: 1,
                  borderTop: `1px solid ${alpha(theme.palette.divider, 0.5)}`
                }}>
                  <Button
                    variant="outlined"
                    onClick={handleDateClose}
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      fontWeight: 600,
                      borderColor: alpha(theme.palette.primary.main, 0.5),
                      '&:hover': {
                        borderColor: theme.palette.primary.main,
                        backgroundColor: alpha(theme.palette.primary.main, 0.08),
                      }
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleConfirm}
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      fontWeight: 600,
                      background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                      boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.3)}`,
                      '&:hover': {
                        boxShadow: `0 6px 16px ${alpha(theme.palette.primary.main, 0.4)}`,
                      }
                    }}
                  >
                    Confirmar
                  </Button>
                </Box>
              </Stack>
            </Box>
          </LocalizationProvider>
        </Box>
      </Popover>

      <Grid 
        container 
        spacing={3} 
        sx={{
          '& .MuiGrid-item': {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Grid 
          item 
          sx={{
            width: {
              xs: '100%',              // Abaixo de 800px
              customBreakpoint: '50%', // Entre 800px e 1200px
              lg: '25%'                // Acima de 1200px
            },
            flexGrow: 1,
          }}
        >
          <StatCard
            title="Total de Tokens"
            value={formatNumber(data.stats.totalTokens)}
            subtitle={formatDateRange(startDate, endDate)}
            icon={TokenIcon}
            color={theme.palette.primary.main}
            tooltip={data.stats.totalTokens}
          />
        </Grid>
        <Grid 
          item 
          sx={{
            width: {
              xs: '100%',              // Abaixo de 800px
              customBreakpoint: '50%', // Entre 800px e 1200px
              lg: '25%'                // Acima de 1200px
            },
            flexGrow: 1,
          }}
        >
          <StatCard
            title="Total de Mensagens"
            value={formatNumber(data.stats.totalMessages)}
            subtitle={formatDateRange(startDate, endDate)}
            icon={MessageIcon}
            color={theme.palette.success.main}
          />
        </Grid>
        <Grid 
          item 
          sx={{
            width: {
              xs: '100%',              // Abaixo de 800px
              customBreakpoint: '50%', // Entre 800px e 1200px
              lg: '25%'                // Acima de 1200px
            },
            flexGrow: 1,
          }}
        >
          <StatCard
            title="Usuários Ativos"
            value={formatNumber(data.stats.activeUsers)}
            subtitle={formatDateRange(startDate, endDate)}
            icon={GroupIcon}
            color={theme.palette.info.main}
          />
        </Grid>
        <Grid 
          item 
          sx={{
            width: {
              xs: '100%',              // Abaixo de 800px
              customBreakpoint: '50%', // Entre 800px e 1200px
              lg: '25%'                // Acima de 1200px
            },
            flexGrow: 1,
          }}
        >
          <StatCard
            title="Média de Tokens/Dia"
            value={formatNumber(data.stats.avgTokensPerDay)}
            subtitle={formatDateRange(startDate, endDate)}
            icon={ShowChartIcon}
            color={theme.palette.warning.main}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{
              p: 3,
              height: '100%',
              minHeight: '400px',
              bgcolor: 'background.paper',
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Uso de Tokens por Dia
            </Typography>
            {data.dailyUsage && data.dailyUsage.length > 0 ? (
              <ResponsiveContainer width="100%" height={350}>
                <AreaChart data={data.dailyUsage}>
                  <defs>
                    <linearGradient id="colorTokens" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.3}/>
                      <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="date" 
                    stroke={theme.palette.text.secondary}
                    tick={{ fill: theme.palette.text.secondary }}
                  />
                  <YAxis 
                    stroke={theme.palette.text.secondary}
                    tick={{ fill: theme.palette.text.secondary }}
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="tokens"
                    stroke={theme.palette.primary.main}
                    fillOpacity={1}
                    fill="url(#colorTokens)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '350px',
                  gap: 2,
                  color: 'text.secondary',
                  bgcolor: alpha(theme.palette.primary.main, 0.04),
                  borderRadius: 2
                }}
              >
                <ShowChartIcon sx={{ fontSize: 60, opacity: 0.5 }} />
                <Typography variant="h6" color="text.secondary">
                  Nenhum dado disponível para o período selecionado
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400, textAlign: 'center' }}>
                  Selecione um período diferente ou verifique se há dados disponíveis para o período atual.
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
