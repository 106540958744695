import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Chip,
  CircularProgress,
  useTheme,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  Divider,
  alpha,
  useMediaQuery,
  Grid,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { supabase } from '../config/supabase';
import EditGlossaryModal from '../components/EditGlossaryModal';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';

function Glossario() {
  const theme = useTheme();
  const isXs = useMediaQuery('(max-width:479px)');
  const isSm = useMediaQuery('(min-width:480px) and (max-width:767px)');
  const isMd = useMediaQuery('(min-width:768px) and (max-width:1023px)');
  const isLg = useMediaQuery('(min-width:1024px) and (max-width:1279px)');
  const isXl = useMediaQuery('(min-width:1280px)');
  
  const isMobile = isXs || isSm;
  const isTablet = isMd;
  const isDesktop = isLg || isXl;

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(isMobile ? 5 : 10);
  const [orderBy, setOrderBy] = useState('codigo');
  const [order, setOrder] = useState('asc');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleEdit = (item) => {
    setSelectedItem(item);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setOpenEditModal(false);
  };

  const handleSaveEdit = async (editedItem) => {
    try {
      const { error } = await supabase
        .from('glossario')
        .update(editedItem)
        .eq('id', editedItem.id);

      if (error) throw error;

      setItems(items.map(item => 
        item.id === editedItem.id ? editedItem : item
      ));
      
      handleCloseEditModal();
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedItem(null);
    setOpenDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const { error } = await supabase
        .from('glossario')
        .delete()
        .eq('id', selectedItem.id);

      if (error) throw error;

      setItems(items.filter(item => item.id !== selectedItem.id));
      handleCloseDeleteModal();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const fetchItems = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('glossario')
        .select('*')
        .order(orderBy, { ascending: order === 'asc' });

      if (error) throw error;

      setItems(data || []);
      setError(null);
    } catch (err) {
      console.error('Erro ao carregar itens do glossário:', err);
      setError('Erro ao carregar os itens do glossário');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [orderBy, order]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = [
    { id: 'codigo', label: 'Código', numeric: false, width: '10%' },
    { id: 'produto', label: 'Produto', numeric: false, width: '20%' },
    { id: 'indicacao_de_uso', label: 'Indicação de Uso', numeric: false, width: '40%' },
    { id: 'composicao', label: 'Composição', numeric: false, width: '10%' },
  ];

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        backgroundColor: theme.palette.background.default 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  const MobileCard = ({ item }) => (
    <Card
      sx={{
        mb: 2,
        borderRadius: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        },
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
                {item.codigo || '-'}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Tooltip title="Editar">
                  <IconButton 
                    size="small"
                    sx={{ 
                      color: theme.palette.primary.main,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      }
                    }}
                    onClick={() => handleEdit(item)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Excluir">
                  <IconButton 
                    size="small"
                    sx={{ 
                      color: theme.palette.error.main,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.error.main, 0.1),
                      }
                    }}
                    onClick={() => handleDelete(item)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {item.produto || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {item.indicacao_de_uso || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Chip
              label={item.composicao || 'Não especificado'}
              size="small"
              sx={{
                maxWidth: '100%',
                '& .MuiChip-label': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'block'
                },
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                color: theme.palette.primary.main,
                fontWeight: 500,
                fontSize: '0.75rem',
                height: '24px',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Box
      sx={{
        bgcolor: 'transparent',
        borderRadius: 2,
        p: 3,
        minHeight: '100%',
        transition: 'background-color 0.3s ease'
      }}
    >
      <Card 
        elevation={0}
        sx={{ 
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          mb: 4,
          borderRadius: 2,
          position: 'relative',
          overflow: 'visible'
        }}
      >
        <CardContent sx={{ py: { xs: 3, sm: 4 }, position: 'relative' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <MenuBookIcon sx={{ fontSize: { xs: 30, sm: 40 } }} />
            <Box>
              <Typography variant="h4" gutterBottom sx={{ 
                fontWeight: 'bold', 
                mb: 0,
                fontSize: { xs: '1.5rem', sm: '2rem' }
              }}>
                Glossário
              </Typography>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  opacity: 0.8,
                  display: { xs: 'none', sm: 'block' }
                }}
              >
                Gerencie seus produtos
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <Box 
          sx={{ 
            position: 'absolute',
            right: 20,
            top: '50%',
            transform: 'translateY(-50%)',
            opacity: 0.2,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <MenuBookIcon sx={{ fontSize: 100 }} />
        </Box>
      </Card>

      {isMobile ? (
        <Box>
          {items
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => (
              <MobileCard key={item.id} item={item} />
            ))}
          <Box sx={{ mt: 2 }}>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component="div"
              count={items.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Por página"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              sx={{
                '.MuiTablePagination-select': {
                  borderRadius: 1,
                  backgroundColor: alpha(theme.palette.primary.main, 0.05),
                }
              }}
            />
          </Box>
        </Box>
      ) : (
        <Card 
          elevation={0}
          sx={{ 
            borderRadius: 2,
            backgroundColor: theme.palette.mode === 'dark' ? 'background.paper' : 'background.paper',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
          }}
        >
          <TableContainer>
            <Table
              sx={{
                tableLayout: 'fixed',
                width: '100%',
                '& td': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }
              }}
            >
              <TableHead>
                <TableRow sx={{ 
                  background: `linear-gradient(45deg, 
                    ${alpha(theme.palette.primary.main, 0.08)} 0%,
                    ${alpha(theme.palette.primary.main, 0.04)} 100%
                  )`,
                  '& th': {
                    borderBottom: 'none',
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                      transform: 'translateY(-1px)'
                    }
                  }
                }}>
                  <TableCell sx={{ 
                    width: '10%',
                    minWidth: '80px',
                    display: { xs: 'none', sm: 'table-cell' }
                  }}>
                    Código
                  </TableCell>
                  <TableCell sx={{ 
                    width: '20%',
                    minWidth: '200px'
                  }}>
                    Produto
                  </TableCell>
                  <TableCell sx={{ 
                    width: '40%',
                    minWidth: '300px'
                  }}>
                    Indicação de Uso
                  </TableCell>
                  <TableCell sx={{ 
                    width: '20%',
                    minWidth: '120px'
                  }}>
                    Composição
                  </TableCell>
                  <TableCell sx={{ 
                    width: '10%',
                    minWidth: '100px'
                  }} align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <TableRow
                      hover
                      key={item.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.05),
                          '& td': {
                            color: theme.palette.text.primary,
                            borderColor: alpha(theme.palette.primary.main, 0.2),
                          },
                          '& .MuiTypography-root': {
                            color: theme.palette.primary.main,
                          },
                          '& .MuiChip-root': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.15),
                          }
                        }
                      }}
                    >
                      <TableCell sx={{ 
                        width: '10%',
                        minWidth: '80px',
                        display: { xs: 'none', sm: 'table-cell' }
                      }}>
                        <Typography variant="body2" sx={{ fontWeight: 500, color: theme.palette.primary.main }}>
                          {item.codigo || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ 
                        width: '20%',
                        minWidth: '200px'
                      }}>
                        <Typography variant="body2" noWrap={false}>
                          {item.produto || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ 
                        width: '40%',
                        minWidth: '300px'
                      }}>
                        <Typography variant="body2" noWrap={false}>
                          {item.indicacao_de_uso || '-'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ 
                        width: '20%',
                        minWidth: '120px'
                      }}>
                        <Chip
                          label={item.composicao || 'Não especificado'}
                          size="small"
                          sx={{
                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                            color: theme.palette.primary.main,
                            maxWidth: '100%',
                            overflow: 'hidden'
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ 
                        width: '10%',
                        minWidth: '100px'
                      }} align="right">
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                          <Tooltip title="Editar">
                            <IconButton 
                              onClick={() => handleEdit(item)}
                              size="small"
                              sx={{ 
                                color: theme.palette.primary.main,
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                }
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Excluir">
                            <IconButton 
                              onClick={() => handleDelete(item)}
                              size="small"
                              sx={{ 
                                color: theme.palette.error.main,
                                '&:hover': {
                                  backgroundColor: alpha(theme.palette.error.main, 0.1),
                                }
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ p: 2 }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={items.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Por página"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              sx={{
                '.MuiTablePagination-select': {
                  borderRadius: 1,
                  backgroundColor: alpha(theme.palette.primary.main, 0.05),
                }
              }}
            />
          </Box>
        </Card>
      )}

      {openEditModal && (
        <EditGlossaryModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          item={selectedItem}
          onSave={handleSaveEdit}
        />
      )}

      {openDeleteModal && (
        <DeleteConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleConfirmDelete}
          itemName={selectedItem?.produto}
        />
      )}
    </Box>
  );
}

export default Glossario;
