import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  styled,
  AppBar,
  alpha,
  Toolbar,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Logout as LogoutIcon,
  Dashboard as DashboardIcon,
  Book as BookIcon,
  Inventory as InventoryIcon,
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
} from '@mui/icons-material';

const drawerWidth = 240;

const StyledBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(0.5),
  position: 'relative',
  transition: 'all 0.2s ease',
  '&:before': {
    content: '""',
    position: 'absolute',
    left: '-16px',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '60%',
    width: '3px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0 4px 4px 0',
    opacity: selected ? 1 : 0,
    transition: 'opacity 0.2s ease'
  },
  backgroundColor: selected ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  '& .MuiListItemIcon-root': {
    color: selected ? theme.palette.primary.main : 'inherit',
    minWidth: '40px'
  },
  '& .MuiListItemText-primary': {
    color: selected ? theme.palette.primary.main : 'inherit',
    fontWeight: selected ? 600 : 400
  },
  '&:hover': {
    backgroundColor: selected 
      ? alpha(theme.palette.primary.main, 0.15)
      : alpha(theme.palette.primary.main, 0.05),
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: selected ? theme.palette.primary.main : theme.palette.primary.light
    }
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  overflow: 'hidden',
}));

const Sidebar = ({ toggleTheme, isDark }) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const drawer = (
    <StyledBox>
      {!isMobile && (
        <LogoContainer>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Oceano
          </Typography>
        </LogoContainer>
      )}

      <Box sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
        <List>
          <StyledListItem
            button
            selected={location.pathname === '/dashboard'}
            onClick={() => {
              navigate('/dashboard');
              if (isMobile) handleDrawerToggle();
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Painel" />
          </StyledListItem>

          <StyledListItem
            button
            selected={location.pathname === '/catalogo'}
            onClick={() => {
              navigate('/catalogo');
              if (isMobile) handleDrawerToggle();
            }}
          >
            <ListItemIcon>
              <InventoryIcon />
            </ListItemIcon>
            <ListItemText primary="Catálogo" />
          </StyledListItem>

          <StyledListItem
            button
            selected={location.pathname === '/glossario'}
            onClick={() => {
              navigate('/glossario');
              if (isMobile) handleDrawerToggle();
            }}
          >
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText primary="Glossário" />
          </StyledListItem>
        </List>
      </Box>

      <List sx={{ mt: 'auto', pb: 2 }}>
        <StyledListItem
          button
          onClick={toggleTheme}
          sx={{
            '&:before': {
              display: 'none'
            },
            '& .MuiListItemIcon-root': {
              minWidth: '40px'
            }
          }}
        >
          <ListItemIcon>
            {isDark ? <LightModeIcon /> : <DarkModeIcon />}
          </ListItemIcon>
          <ListItemText 
            primary={isDark ? "Tema Claro" : "Tema Escuro"} 
            sx={{ 
              '& .MuiTypography-root': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            }}
          />
        </StyledListItem>

        <StyledListItem
          button
          onClick={handleLogout}
          sx={{
            '&:before': {
              display: 'none'
            },
            '& .MuiListItemIcon-root': {
              color: theme.palette.error.main,
              minWidth: '40px'
            },
            '& .MuiTypography-root': {
              color: theme.palette.error.main,
              fontWeight: 500
            },
            '&:hover': {
              backgroundColor: alpha(theme.palette.error.main, 0.1),
              '& .MuiListItemIcon-root, & .MuiTypography-root': {
                color: theme.palette.error.dark
              }
            }
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </StyledListItem>
      </List>
    </StyledBox>
  );

  return (
    <>
      {isMobile && (
        <AppBar 
          position="fixed" 
          sx={{ 
            bgcolor: '#1a237e',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Oceano
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            border: 'none',
            bgcolor: 'background.paper',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
            ...(isMobile && {
              top: '56px', // Altura do AppBar em mobile
              height: 'calc(100% - 56px)',
            }),
          },
          ...(isMobile && {
            '& .MuiBackdrop-root': {
              top: '56px',
              height: 'calc(100% - 56px)',
            }
          })
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Sidebar;