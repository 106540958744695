import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Chip,
  CircularProgress,
  useTheme,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  Divider,
  alpha,
  useMediaQuery,
  Grid,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InventoryIcon from '@mui/icons-material/Inventory';
import { supabase } from '../config/supabase';
import EditItemModal from '../components/EditItemModal';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';

function Catalogo() {
  const theme = useTheme();
  const isXs = useMediaQuery('(max-width:479px)');
  const isSm = useMediaQuery('(min-width:480px) and (max-width:767px)');
  const isMd = useMediaQuery('(min-width:768px) and (max-width:1023px)');
  const isLg = useMediaQuery('(min-width:1024px) and (max-width:1279px)');
  const isXl = useMediaQuery('(min-width:1280px)');
  
  const isMobile = isXs || isSm;
  const isTablet = isMd;
  const isDesktop = isLg || isXl;

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(isMobile ? 5 : 10);
  const [orderBy, setOrderBy] = useState('codigo');
  const [order, setOrder] = useState('asc');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleEdit = (item) => {
    setSelectedItem(item);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(null);
    setOpenEditModal(false);
  };

  const handleSaveEdit = async (editedItem) => {
    try {
      const { error } = await supabase
        .from('catalogo')
        .update({
          tipo_de_peca: editedItem.tipo_de_peca,
          descricao: editedItem.descricao,
          tecnica: editedItem.tecnica,
          tecido_principal: editedItem.tecido_principal,
          venda: editedItem.venda,
          preco_aprovado: editedItem.preco_aprovado,
          molde_base: editedItem.molde_base,
          grade_erp: editedItem.grade_erp,
          observacoes: editedItem.observacoes,
          cor_variante_1: editedItem.cor_variante_1,
          cor_variante_2: editedItem.cor_variante_2,
          cor_variante_3: editedItem.cor_variante_3,
          cor_variante_4: editedItem.cor_variante_4,
          cor_variante_5: editedItem.cor_variante_5,
          cor_variante_6: editedItem.cor_variante_6,
          cor_variante_7: editedItem.cor_variante_7,
          cor_variante_8: editedItem.cor_variante_8,
          cor_variante_9: editedItem.cor_variante_9,
          cor_variante_10: editedItem.cor_variante_10,
          cor_variante_11: editedItem.cor_variante_11,
          cor_variante_12: editedItem.cor_variante_12
        })
        .eq('id', editedItem.id);

      if (error) throw error;

      // Update local state
      setItems(items.map(item => 
        item.id === editedItem.id ? {
          ...item,
          ...editedItem
        } : item
      ));
      
      handleCloseEditModal();
      // You might want to add a success notification here
    } catch (error) {
      console.error('Error updating item:', error);
      // You might want to add an error notification here
    }
  };

  const handleDelete = (item) => {
    setSelectedItem(item);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedItem(null);
    setOpenDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const { error } = await supabase
        .from('catalogo')
        .delete()
        .eq('id', selectedItem.id);

      if (error) throw error;

      // Update local state
      setItems(items.filter(item => item.id !== selectedItem.id));
      
      handleCloseDeleteModal();
      // You might want to add a success notification here
    } catch (error) {
      console.error('Error deleting item:', error);
      // You might want to add an error notification here
    }
  };

  const fetchCatalogItems = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('catalogo')
        .select('*')
        .order(orderBy, { ascending: order === 'asc' });

      if (error) {
        throw error;
      }

      setItems(data || []);
      setError(null);
    } catch (err) {
      console.error('Erro ao carregar itens do catálogo:', err);
      setError('Erro ao carregar os itens do catálogo');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCatalogItems();
  }, [orderBy, order]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = [
    { id: 'codigo', label: 'Código', numeric: false },
    { id: 'descricao', label: 'Descrição', numeric: false },
    { id: 'tecido_principal', label: 'Tecido Principal', numeric: false },
    { id: 'preco_aprovado', label: 'Preço', numeric: true },
  ];

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        backgroundColor: theme.palette.background.default 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  const MobileCard = ({ item }) => (
    <Card
      sx={{
        mb: 2,
        borderRadius: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        },
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
                {item.codigo || '-'}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Tooltip title="Editar">
                  <IconButton 
                    size="small"
                    sx={{ 
                      color: theme.palette.primary.main,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      }
                    }}
                    onClick={() => handleEdit(item)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Excluir">
                  <IconButton 
                    size="small"
                    sx={{ 
                      color: theme.palette.error.main,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.error.main, 0.1),
                      }
                    }}
                    onClick={() => handleDelete(item)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {item.descricao || '-'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Chip
              label={item.tecido_principal || 'Não especificado'}
              size="small"
              sx={{
                maxWidth: '150px',
                '& .MuiChip-label': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'block'
                },
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                color: theme.palette.primary.main,
                fontWeight: 500,
                fontSize: '0.75rem',
                height: '24px',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Typography variant="body2" sx={{ 
              fontWeight: 600,
              color: theme.palette.success.main
            }}>
              {item.preco_aprovado ? `R$ ${parseFloat(item.preco_aprovado).toFixed(2)}` : '-'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ 
      p: { xs: 2, sm: 3 }, 
      backgroundColor: theme.palette.background.default,
      minHeight: '100vh'
    }}>
      <Card 
        elevation={0}
        sx={{ 
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          mb: 4,
          borderRadius: 2,
          position: 'relative',
          overflow: 'visible'
        }}
      >
        <CardContent sx={{ py: { xs: 3, sm: 4 }, position: 'relative' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <InventoryIcon sx={{ fontSize: { xs: 30, sm: 40 } }} />
            <Box>
              <Typography variant="h4" gutterBottom sx={{ 
                fontWeight: 'bold', 
                mb: 0,
                fontSize: { xs: '1.5rem', sm: '2rem' }
              }}>
                Catálogo
              </Typography>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  opacity: 0.8,
                  display: { xs: 'none', sm: 'block' }
                }}
              >
                Gerencie seus produtos
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <Box 
          sx={{ 
            position: 'absolute',
            right: 20,
            top: '50%',
            transform: 'translateY(-50%)',
            opacity: 0.2,
            display: { xs: 'none', sm: 'block' }
          }}
        >
          <InventoryIcon sx={{ fontSize: 100 }} />
        </Box>
      </Card>

      {isMobile ? (
        <Box>
          {items
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => (
              <MobileCard key={item.id} item={item} />
            ))}
          <Box sx={{ mt: 2 }}>
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component="div"
              count={items.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Por página"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              sx={{
                '.MuiTablePagination-select': {
                  borderRadius: 1,
                  backgroundColor: alpha(theme.palette.primary.main, 0.05),
                }
              }}
            />
          </Box>
        </Box>
      ) : (
        <Card 
          elevation={0}
          sx={{ 
            borderRadius: 2,
            backgroundColor: 'white',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
          }}
        >
          <TableContainer>
            <Table size={isMobile ? 'small' : 'medium'}>
              <TableHead>
                <TableRow sx={{ 
                  background: `linear-gradient(45deg, 
                    ${alpha(theme.palette.primary.main, 0.08)} 0%,
                    ${alpha(theme.palette.primary.main, 0.04)} 100%
                  )`,
                  '& th': {
                    borderBottom: 'none',
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                      transform: 'translateY(-1px)'
                    }
                  }
                }}>
                  <TableCell
                    sx={{ 
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      py: { xs: 1.5, sm: 2 },
                      display: { xs: 'none', sm: 'table-cell' },
                      width: { 
                        sm: '15%',
                        md: '12%',
                        lg: '10%',
                        xl: '8%'
                      }
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'codigo'}
                      direction={orderBy === 'codigo' ? order : 'asc'}
                      onClick={() => handleRequestSort('codigo')}
                    >
                      Código
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ 
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      py: { xs: 1.5, sm: 2 },
                      width: { 
                        xs: '60%',
                        sm: '45%',
                        md: '40%',
                        lg: '35%',
                        xl: '30%'
                      }
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'descricao'}
                      direction={orderBy === 'descricao' ? order : 'asc'}
                      onClick={() => handleRequestSort('descricao')}
                    >
                      Descrição
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ 
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      py: { xs: 1.5, sm: 2 },
                      display: { xs: 'none', md: 'table-cell' },
                      width: { 
                        md: '25%',
                        lg: '30%',
                        xl: '35%'
                      }
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'tecido_principal'}
                      direction={orderBy === 'tecido_principal' ? order : 'asc'}
                      onClick={() => handleRequestSort('tecido_principal')}
                    >
                      Tecido Principal
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ 
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      py: { xs: 1.5, sm: 2 },
                      display: { xs: 'none', sm: 'table-cell' },
                      width: { 
                        sm: '20%',
                        md: '15%',
                        lg: '15%',
                        xl: '12%'
                      }
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === 'preco_aprovado'}
                      direction={orderBy === 'preco_aprovado' ? order : 'asc'}
                      onClick={() => handleRequestSort('preco_aprovado')}
                    >
                      Preço
                    </TableSortLabel>
                  </TableCell>
                  <TableCell 
                    align="right"
                    sx={{ 
                      fontWeight: 'bold',
                      color: theme.palette.text.primary,
                      py: { xs: 1, sm: 2 },
                      width: { 
                        xs: '40%',
                        sm: '100px',
                        md: '90px',
                        lg: '80px',
                        xl: '80px'
                      }
                    }}
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : items
                ).map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.05),
                        '& td': {
                          color: theme.palette.text.primary,
                          borderColor: alpha(theme.palette.primary.main, 0.2),
                        },
                        '& .MuiTypography-root': {
                          color: theme.palette.primary.main,
                        },
                        '& .MuiChip-root': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.15),
                        }
                      }
                    }}
                  >
                    <TableCell 
                      sx={{ 
                        display: { xs: 'none', sm: 'table-cell' },
                        py: { xs: 1.5, sm: 2 }
                      }}
                    >
                      <Typography variant="body2" sx={{ 
                        fontWeight: 500, 
                        color: theme.palette.primary.main,
                        fontSize: { 
                          xs: '0.813rem',
                          sm: '0.875rem',
                          md: '0.875rem',
                          lg: '0.875rem'
                        }
                      }}>
                        {item.codigo || '-'}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: { xs: 1.5, sm: 2 } }}>
                      <Box>
                        <Typography variant="body2" sx={{ 
                          fontWeight: 500,
                          display: { xs: 'block', sm: 'none' },
                          color: theme.palette.primary.main,
                          mb: 0.5,
                          fontSize: { 
                            xs: '0.813rem',
                            sm: '0.875rem'
                          }
                        }}>
                          {item.codigo || '-'}
                        </Typography>
                        <Typography variant="body2" sx={{ 
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: { 
                            xs: 2,
                            sm: 2,
                            md: 2,
                            lg: 3,
                            xl: 3
                          },
                          WebkitBoxOrient: 'vertical',
                          lineHeight: '1.3em',
                          maxHeight: { 
                            xs: '2.6em',
                            sm: '2.6em',
                            md: '2.6em',
                            lg: '3.9em',
                            xl: '3.9em'
                          },
                          fontSize: { 
                            xs: '0.813rem',
                            sm: '0.875rem',
                            md: '0.875rem',
                            lg: '0.875rem'
                          },
                          color: theme.palette.text.primary
                        }}>
                          {item.descricao || '-'}
                        </Typography>
                        <Box sx={{ 
                          mt: 1,
                          display: { xs: 'flex', md: 'none' },
                          alignItems: 'center',
                          gap: { xs: 0.5, sm: 1 },
                          flexWrap: 'wrap'
                        }}>
                          <Typography variant="body2" sx={{ 
                            fontWeight: 600,
                            color: theme.palette.success.main,
                            display: { xs: 'block', sm: 'none' },
                            fontSize: { 
                              xs: '0.813rem',
                              sm: '0.875rem'
                            }
                          }}>
                            {item.preco_aprovado ? `R$ ${parseFloat(item.preco_aprovado).toFixed(2)}` : '-'}
                          </Typography>
                          <Chip
                            label={item.tecido_principal || 'Não especificado'}
                            size="small"
                            sx={{
                              maxWidth: { 
                                xs: '120px',
                                sm: '150px',
                                md: '180px'
                              },
                              '& .MuiChip-label': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                display: 'block'
                              },
                              backgroundColor: alpha(theme.palette.primary.main, 0.1),
                              color: theme.palette.primary.main,
                              fontWeight: 500,
                              fontSize: { 
                                xs: '0.75rem',
                                sm: '0.813rem'
                              },
                              height: { 
                                xs: '20px',
                                sm: '24px'
                              },
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.2),
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ 
                        display: { xs: 'none', md: 'table-cell' },
                        py: { xs: 1.5, sm: 2 },
                        maxWidth: { 
                          md: '180px',
                          lg: '220px',
                          xl: '250px'
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: {
                            md: 2,
                            lg: 2,
                            xl: 3
                          },
                          WebkitBoxOrient: 'vertical',
                          lineHeight: '1.2em',
                          maxHeight: {
                            md: '2.4em',
                            lg: '2.4em',
                            xl: '3.6em'
                          },
                          fontSize: { 
                            md: '0.875rem',
                            lg: '0.875rem'
                          },
                          color: theme.palette.text.primary
                        }}
                      >
                        {item.tecido_principal || 'Não especificado'}
                      </Typography>
                    </TableCell>
                    <TableCell 
                      align="right"
                      sx={{ 
                        display: { xs: 'none', sm: 'table-cell' },
                        py: { xs: 1.5, sm: 2 }
                      }}
                    >
                      <Typography variant="body2" sx={{ 
                        fontWeight: 600,
                        color: theme.palette.success.main,
                        fontSize: { 
                          sm: '0.875rem',
                          md: '0.875rem',
                          lg: '0.875rem'
                        }
                      }}>
                        {item.preco_aprovado ? `R$ ${parseFloat(item.preco_aprovado).toFixed(2)}` : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell align="right" sx={{ py: { xs: 1, sm: 2 } }}>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        gap: { xs: 0.5, sm: 1 }
                      }}>
                        <Tooltip title="Editar">
                          <IconButton 
                            size={isMobile ? 'small' : 'medium'}
                            sx={{ 
                              color: theme.palette.primary.main,
                              padding: { 
                                xs: 0.5,
                                sm: 0.75,
                                md: 1
                              },
                              transition: 'all 0.2s ease',
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                transform: 'scale(1.1)',
                              }
                            }}
                            onClick={() => handleEdit(item)}
                          >
                            <EditIcon sx={{ 
                              fontSize: { 
                                xs: '0.875rem',
                                sm: '1rem',
                                md: '1.25rem'
                              }
                            }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Excluir">
                          <IconButton 
                            size={isMobile ? 'small' : 'medium'}
                            sx={{ 
                              color: theme.palette.error.main,
                              padding: { 
                                xs: 0.5,
                                sm: 0.75,
                                md: 1
                              },
                              transition: 'all 0.2s ease',
                              '&:hover': {
                                backgroundColor: alpha(theme.palette.error.main, 0.1),
                                transform: 'scale(1.1)',
                              }
                            }}
                            onClick={() => handleDelete(item)}
                          >
                            <DeleteIcon sx={{ 
                              fontSize: { 
                                xs: '0.875rem',
                                sm: '1rem',
                                md: '1.25rem'
                              }
                            }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ 
              py: { xs: 1, sm: 1.5, md: 2 },
              px: { xs: 1, sm: 1.5, md: 2 }
            }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={isMobile ? 'Por página:' : 'Itens por página:'}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                sx={{
                  '.MuiTablePagination-select': {
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08)
                    }
                  },
                  '.MuiIconButton-root': {
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                      transform: 'translateY(-1px)'
                    }
                  }
                }}
              />
            </Box>
          </TableContainer>
        </Card>
      )}
      <EditItemModal
        open={openEditModal}
        onClose={handleCloseEditModal}
        item={selectedItem}
        onSave={handleSaveEdit}
      />

      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        item={selectedItem}
        onConfirm={handleConfirmDelete}
      />
    </Box>
  );
}

export default Catalogo;
