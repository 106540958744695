import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  useTheme,
} from '@mui/material';

const DeleteConfirmationModal = ({ open, onClose, item, onConfirm }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle sx={{ 
        color: theme.palette.error.main,
        pb: 1
      }}>
        Confirmar Exclusão
      </DialogTitle>
      <DialogContent sx={{ pt: 2 }}>
        <Typography>
          Tem certeza que deseja excluir este item?
          {item && (
            <Box component="span" sx={{ fontWeight: 600 }}>
              {` ${item.codigo}`}
            </Box>
          )}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1 }}
        >
          Esta ação não poderá ser desfeita.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button 
          onClick={onClose}
          sx={{ color: theme.palette.text.secondary }}
        >
          Cancelar
        </Button>
        <Button 
          onClick={onConfirm}
          variant="contained"
          color="error"
          sx={{
            '&:hover': {
              bgcolor: theme.palette.error.dark,
            }
          }}
        >
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
