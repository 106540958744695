import axios from 'axios';

const OPENAI_API_URL = 'https://api.openai.com/v1/usage';

const getApiKey = () => {
  return process.env.REACT_APP_OPENAI_API_KEY || 
         window._env_?.REACT_APP_OPENAI_API_KEY ||
         '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';
};

const generateMockData = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const daysDiff = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
  
  // Generate daily costs with more realistic values
  const daily_costs = Array.from({ length: daysDiff }, (_, i) => {
    const dailyCost = Math.random() * 5 + 1; // Random cost between $1 and $6 per day
    return {
      timestamp: new Date(start.getTime() + i * 24 * 60 * 60 * 1000).getTime() / 1000,
      cost: dailyCost
    };
  });

  // Calculate total cost
  const totalCost = daily_costs.reduce((sum, day) => sum + day.cost, 0);
  
  return {
    total_tokens: Math.floor(totalCost / 0.002), // Convert total cost to tokens
    daily_costs
  };
};

export const getTokenUsage = async (startDate, endDate) => {
  try {
    const apiKey = getApiKey();
    if (!apiKey) {
      console.error('Chave da API OpenAI não encontrada');
      return { total_tokens: 0, daily_costs: [] };
    }

    // Se não foram fornecidas datas, usa o mês atual
    if (!startDate || !endDate) {
      const now = new Date();
      startDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split('T')[0];
      endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split('T')[0];
    }

    // Validação das datas
    if (new Date(startDate) > new Date(endDate)) {
      console.error('A data de início não pode ser posterior à data de término');
      return { total_tokens: 0, daily_costs: [] };
    }

    const response = await axios.get(OPENAI_API_URL, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      },
      params: {
        start_date: startDate,
        end_date: endDate
      }
    });

    if (!response.data || !response.data.daily_costs) {
      console.error('Dados inválidos recebidos da API');
      return { total_tokens: 0, daily_costs: [] };
    }

    const daily_costs = response.data.daily_costs;
    const totalCost = daily_costs.reduce((sum, day) => sum + day.cost, 0);
    
    return {
      total_tokens: Math.floor(totalCost / 0.002),
      daily_costs: daily_costs
    };
  } catch (error) {
    console.error('Erro ao buscar uso de tokens:', error.message);
    return { total_tokens: 0, daily_costs: [] };
  }
};
