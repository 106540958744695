import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box, ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme/theme';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Catalogo from './pages/Catalogo';
import Glossario from './pages/Glossario';

function App() {
  // Inicializa o estado com o valor do localStorage ou 'light' como padrão
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem('themeMode');
    return savedMode || 'light';
  });

  // Atualiza o localStorage sempre que o modo mudar
  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  }, [mode]);

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeProvider theme={theme(mode)}>
      <CssBaseline />
      <Router>
        <Box sx={{ 
          display: 'flex', 
          bgcolor: 'background.default', 
          minHeight: '100vh',
          transition: 'background-color 0.3s ease'
        }}>
          <Sidebar toggleTheme={toggleTheme} isDark={mode === 'dark'} />
          <Box 
            component="main" 
            sx={{ 
              flexGrow: 1, 
              p: 3, 
              width: { sm: `calc(100% - ${240}px)` },
              ml: { sm: `${240}px` },
              mt: { xs: '64px', sm: '0px' },
              color: 'text.primary',
              bgcolor: 'background.default',
              transition: 'background-color 0.3s ease'
            }}
          >
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/catalogo" element={<Catalogo />} />
              <Route path="/glossario" element={<Glossario />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;