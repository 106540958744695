import { createTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

const colors = {
  primary: {
    main: '#006994',      // Azul oceânico profundo
    light: '#0099CC',     // Azul oceânico claro
    dark: '#004C6D',      // Azul oceânico escuro
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#00B4B4',      // Verde-água
    light: '#00D4D4',     // Verde-água claro
    dark: '#008080',      // Verde-água escuro
    contrastText: '#FFFFFF'
  },
  error: {
    main: '#FF4B4B',      // Vermelho coral
    light: '#FF6B6B',     // Vermelho coral claro
    dark: '#CC3B3B',      // Vermelho coral escuro
    contrastText: '#FFFFFF'
  },
  warning: {
    main: '#FFB74D',      // Laranja areia
    light: '#FFC77D',     // Laranja areia claro
    dark: '#CC924D',      // Laranja areia escuro
    contrastText: '#000000'
  },
  grey: {
    50: '#F8FBFD',        // Branco azulado muito claro
    100: '#F1F7FA',       // Branco azulado claro
    200: '#E1EEF3',       // Cinza azulado muito claro
    300: '#B2D4E1',       // Cinza azulado claro
    400: '#89B9CC',       // Cinza azulado médio
    500: '#5C9BB3',       // Azul acinzentado médio
    600: '#4B89A3',       // Azul acinzentado
    700: '#3A7793',       // Azul acinzentado escuro
    800: '#2B839F',       // Azul acinzentado
    900: '#006D8C',       // Azul escuro acinzentado
  }
};

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // Light mode
          primary: colors.primary,
          secondary: colors.secondary,
          error: colors.error,
          warning: colors.warning,
          grey: colors.grey,
          background: {
            default: '#F8FBFD',   // Branco azulado muito claro
            paper: '#FFFFFF',     // Branco puro
          },
          text: {
            primary: '#1A3C4D',   // Azul escuro para texto principal
            secondary: '#4A6572', // Azul médio para texto secundário
            disabled: '#90A4AE',  // Azul claro para texto desabilitado
          },
          divider: '#E1EEF3'      // Cinza azulado muito claro para divisores
        }
      : {
          // Dark mode
          primary: {
            ...colors.primary,
            main: '#0099CC',      // Azul mais brilhante para dark mode
          },
          secondary: {
            ...colors.secondary,
            main: '#00D4D4',      // Verde-água mais brilhante para dark mode
          },
          error: colors.error,
          warning: colors.warning,
          grey: colors.grey,
          background: {
            default: '#0A1929',   // Azul escuro profundo
            paper: '#132F4C',     // Azul escuro médio
          },
          text: {
            primary: '#FFFFFF',    // Branco para texto principal
            secondary: '#B2BAC2',  // Cinza claro para texto secundário
            disabled: '#616E74',   // Cinza escuro para texto desabilitado
          },
          divider: 'rgba(194, 224, 255, 0.08)'  // Divisor sutil para dark mode
        }),
  },
  typography: {
    fontFamily: "'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontWeight: 600,
      fontSize: '2.375rem',
      lineHeight: 1.21,
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.875rem',
      lineHeight: 1.27,
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.33,
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: 1.4,
    },
    h5: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    h6: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.57,
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: 1.57,
    },
    body2: {
      fontSize: '0.75rem',
      lineHeight: 1.66,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.57,
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.66,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          transition: 'background-color 0.3s ease'
        }
      }
    },
    MuiBox: {
      defaultProps: {
        sx: {
          bgcolor: 'background.paper'
        }
      },
      styleOverrides: {
        root: ({ theme }) => ({
          transition: 'background-color 0.3s ease',
        })
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 500
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' 
            ? theme.palette.background.paper 
            : theme.palette.background.paper,
          borderRadius: 12,
          boxShadow: theme.palette.mode === 'dark'
            ? '0px 4px 20px rgba(0, 0, 0, 0.4)'
            : '0px 4px 20px rgba(0, 105, 148, 0.08)'
        })
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          transition: 'background-color 0.3s ease'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary.main
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark'
            ? theme.palette.background.paper
            : theme.palette.background.default,
        })
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark'
            ? theme.palette.background.paper
            : theme.palette.background.default,
        })
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark'
            ? theme.palette.background.paper
            : theme.palette.background.default,
          borderBottom: `1px solid ${
            theme.palette.mode === 'dark' 
              ? alpha(theme.palette.primary.light, 0.2)
              : theme.palette.divider
          }`,
          color: theme.palette.text.primary,
        }),
        head: ({ theme }) => ({
          fontWeight: 600,
          backgroundColor: theme.palette.mode === 'dark' 
            ? alpha(theme.palette.primary.dark, 0.2)
            : theme.palette.background.default,
          color: theme.palette.text.primary,
          borderBottom: `2px solid ${
            theme.palette.mode === 'dark' 
              ? theme.palette.primary.light
              : theme.palette.primary.main
          }`,
        }),
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark'
            ? theme.palette.background.paper
            : theme.palette.background.default,
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.mode === 'dark' 
              ? alpha(theme.palette.primary.light, 0.05)
              : alpha(theme.palette.primary.main, 0.03),
          },
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark'
              ? alpha(theme.palette.primary.light, 0.1)
              : alpha(theme.palette.primary.main, 0.05),
          },
          borderBottom: `1px solid ${
            theme.palette.mode === 'dark' 
              ? alpha(theme.palette.primary.light, 0.2)
              : theme.palette.divider
          }`,
        }),
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
        paper: ({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' 
            ? theme.palette.background.paper
            : theme.palette.background.default,
          borderRadius: 0,
        }),
        docked: {
          backgroundColor: 'transparent',
        }
      }
    },
    MuiTableFooter: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' 
            ? theme.palette.background.paper 
            : theme.palette.background.default,
          '& .MuiTableCell-root': {
            backgroundColor: 'inherit',
            color: theme.palette.text.primary
          },
          '& .MuiToolbar-root': {
            backgroundColor: theme.palette.mode === 'dark' 
              ? theme.palette.background.paper 
              : theme.palette.background.default,
          }
        })
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' 
            ? theme.palette.background.paper 
            : theme.palette.background.default,
        })
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          backgroundColor: 'inherit',
        }),
        selectLabel: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        displayedRows: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        select: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
        actions: ({ theme }) => ({
          color: theme.palette.text.primary,
          '& .MuiIconButton-root': {
            color: theme.palette.text.primary,
          },
        }),
        toolbar: ({ theme }) => ({
          backgroundColor: theme.palette.mode === 'dark' 
            ? theme.palette.background.paper 
            : theme.palette.background.default,
          color: theme.palette.text.primary,
        }),
      }
    },
  }
});

const theme = (mode) => createTheme(getDesignTokens(mode));

export default theme;
