import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { supabase } from '../config/supabase';

const EditGlossaryModal = ({ open, onClose, item, onSave }) => {
  const [editedItem, setEditedItem] = useState({
    produto: '',
    codigo: '',
    descricao: '',
    composicao: '',
    indicacao_de_uso: '',
    beneficios: '',
    palavras_chave: [],
    caracteristicas: '',
  });

  useEffect(() => {
    if (item) {
      setEditedItem({
        ...item,
        palavras_chave: item.palavras_chave || [],
      });
    } else {
      setEditedItem({
        produto: '',
        codigo: '',
        descricao: '',
        composicao: '',
        indicacao_de_uso: '',
        beneficios: '',
        palavras_chave: [],
        caracteristicas: '',
      });
    }
  }, [item]);

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    if (field === 'palavras_chave') {
      value = value.split(',').map(word => word.trim());
    }
    setEditedItem(prev => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    try {
      let result;
      if (item?.id) {
        const { data, error } = await supabase
          .from('glossario')
          .update(editedItem)
          .eq('id', item.id)
          .select()
          .single();

        if (error) throw error;
        result = data;
      } else {
        const { data, error } = await supabase
          .from('glossario')
          .insert([editedItem])
          .select()
          .single();

        if (error) throw error;
        result = data;
      }

      onSave(result);
      onClose();
    } catch (error) {
      console.error('Error saving item:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ pb: 1, color: theme => theme.palette.primary.main }}>
        <Typography variant="h6" component="div">
          {item ? 'Editar Item' : 'Novo Item'} {editedItem.codigo}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ pt: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Produto"
                fullWidth
                value={editedItem.produto}
                onChange={handleChange('produto')}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Código"
                fullWidth
                value={editedItem.codigo}
                onChange={handleChange('codigo')}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descrição"
                fullWidth
                multiline
                rows={2}
                value={editedItem.descricao}
                onChange={handleChange('descricao')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Composição"
                fullWidth
                multiline
                rows={2}
                value={editedItem.composicao}
                onChange={handleChange('composicao')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Indicação de Uso"
                fullWidth
                multiline
                rows={2}
                value={editedItem.indicacao_de_uso}
                onChange={handleChange('indicacao_de_uso')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Benefícios"
                fullWidth
                multiline
                rows={2}
                value={editedItem.beneficios}
                onChange={handleChange('beneficios')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Palavras-chave (separadas por vírgula)"
                fullWidth
                value={editedItem.palavras_chave.join(', ')}
                onChange={handleChange('palavras_chave')}
                helperText="Ex: palavra1, palavra2, palavra3"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Características"
                fullWidth
                multiline
                rows={2}
                value={editedItem.caracteristicas}
                onChange={handleChange('caracteristicas')}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={!editedItem.produto || !editedItem.codigo}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGlossaryModal;
